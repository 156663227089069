import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetSingleRestaurantCompanyLocationQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantCompanyLocationForm } from '@/components/restaurant/companies/edit-restaurant-company-location-form';

export const EditRestaurantCompanyLocationModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId, locationId } = useParams();

  if (!companyId || !locationId) {
    throw new Error(
      'Company ID and location ID are required for editing location',
    );
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const {
    data: location,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleRestaurantCompanyLocationQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
    location_id: Number(locationId),
  });

  const closeModal = () =>
    navigate(`/restaurant/companies/${companyId}/locations`);

  const isModalOpen = Boolean(
    useMatch({
      path: '/restaurant/companies/:comapnyId/locations/edit/:locationId',
    }),
  );

  return (
    <Modal
      key={locationId + companyId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.edit')}
      description={t('company-locations.edit-details')}
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantCompanyLocationForm
          location={location}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
