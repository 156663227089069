import { toast } from 'sonner';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ValidationError,
  useAuthUserRestaurant,
  useCopyMenusMutation,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Badge } from '@/components/shared/shadcn-ui/badge';
import { DatePicker } from '@/components/shared/date-picker';
import { Switch } from '@/components/shared/shadcn-ui/switch';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { CopyRestaurantMenuContent } from '@/components/restaurant/menus/copy-restaurant-menu-content';

interface MenuState {
  selectedMenus: number[];
  areAllActive: boolean;
}

export const CopyRestaurantMenuModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [singleDatePickerOpen, setSingleDatePickerOpen] = useState(false);

  const [date, setDate] = useState(
    searchParams.get('date') || format(Date.now(), 'yyyy-MM-dd'),
  );

  const [copyMenuDate, setCopyMenuDate] = useState({
    from: '',
    to: '',
  });

  const [menusState, setMenusState] = useState<MenuState>({
    selectedMenus: [],
    areAllActive: false,
  });

  const restaurant = useAuthUserRestaurant();
  const copyRestaurantMenuMutation = useCopyMenusMutation();

  const isModalOpen = Boolean(useMatch({ path: `/restaurant/menus/copy` }));
  const closeModal = () => {
    navigate(`/restaurant/menus?${searchParams.toString()}`);
    setDate(searchParams.get('date') || format(Date.now(), 'yyyy-MM-dd'));
    setMenusState({
      selectedMenus: [],
      areAllActive: false,
    });
  };

  const handleSelectedMenu = (id: number) => {
    setMenusState((prevState) => {
      if (menusState.selectedMenus.includes(id)) {
        return {
          ...prevState,
          selectedMenus: prevState.selectedMenus.filter(
            (currentId) => currentId !== id,
          ),
        };
      }
      return {
        ...prevState,
        selectedMenus: [...prevState.selectedMenus, id],
      };
    });
  };

  const isValid = useMemo(() => {
    return Boolean(
      copyMenuDate.from.trim().length &&
        copyMenuDate.to.trim().length &&
        menusState.selectedMenus.length,
    );
  }, [copyMenuDate.from, copyMenuDate.to, menusState.selectedMenus]);

  const handleRestaurantMenuCopy = async () => {
    if (!isValid) return;
    try {
      await copyRestaurantMenuMutation.mutateAsync({
        restaurant_id: restaurant.id,
        from_date: copyMenuDate.from,
        to_date: copyMenuDate.to,
        menu_ids: menusState.selectedMenus,
        is_active: menusState.areAllActive,
      });
      closeModal();
      setMenusState({
        selectedMenus: [],
        areAllActive: false,
      });
      toast.success(t('menu.copy.success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('menu.copy.error-msg'));
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('menu.copy.title')}
      className="sm:max-w-[calc(100%-4rem)] lg:max-w-[768px] xl:max-w-[1024px] overflow-hidden flex flex-col gap-6"
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-start gap-2">
          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <Label>{t('menu.copy.from')}</Label>
              <DatePicker
                open={singleDatePickerOpen}
                onOpenChange={setSingleDatePickerOpen}
                date={new Date(date)}
                setDate={(newDate) => {
                  if (!newDate) return;
                  setDate(format(newDate, 'yyyy-MM-dd'));
                  setMenusState({
                    selectedMenus: [],
                    areAllActive: false,
                  });
                  setSingleDatePickerOpen(false);
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>{t('menu.copy.to')}</Label>
              <DatePickerWithRange
                date={{
                  from: copyMenuDate.from
                    ? new Date(copyMenuDate.from)
                    : undefined,
                  to: copyMenuDate.to ? new Date(copyMenuDate.to) : undefined,
                }}
                setDate={([from, to]) =>
                  setCopyMenuDate((prevState) => {
                    return {
                      ...prevState,
                      from,
                      to,
                    };
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="are-all-active">{t('menu.activate-all')}</Label>
        <Switch
          id="are-all-active"
          onCheckedChange={(value) =>
            setMenusState((prevState) => {
              return { ...prevState, areAllActive: value };
            })
          }
          checked={menusState.areAllActive}
        />
      </div>
      <CopyRestaurantMenuContent
        date={date}
        selectedMenus={menusState.selectedMenus}
        handleSelectedMenu={handleSelectedMenu}
      />
      <div className="mt-6 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Label>{t('menu.copy.total-chosen-menus')}</Label>
          <Badge>{menusState.selectedMenus.length}</Badge>
        </div>
        <div className="flex gap-2">
          <Button
            onClick={() => {
              setMenusState({
                selectedMenus: [],
                areAllActive: false,
              });
              closeModal();
            }}
            variant="outline"
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handleRestaurantMenuCopy}
            disabled={!isValid}
            isLoading={copyRestaurantMenuMutation.isPending}
          >
            {t('common.copy')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
