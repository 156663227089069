import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantPartnerForm } from '@/components/restaurant/partners/create-restaurant-partner-form';

export const CreateRestaurantPartnerModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => navigate('/restaurant/partners');

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/partners/create' }),
  );

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('partner.create')}
      description={t('partner.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <CreateRestaurantPartnerForm closeModal={closeModal} />
    </Modal>
  );
};
