import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetSingleRestaurantEmployeeQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantEmployeeForm } from '@/components/restaurant/employees/edit-restaurant-employee-form';

export const EditRestaurantEmployeeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams();

  if (!employeeId) {
    throw new Error('Employee ID is required for editing employee');
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const {
    data: employee,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleRestaurantEmployeeQuery({
    employee_id: Number(employeeId),
    restaurant_id: restaurant.id,
  });

  const closeModal = () => navigate('/restaurant/employees');

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/employees/edit/:employeeId' }),
  );

  return (
    <Modal
      key={employeeId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('employee.edit')}
      description={t('employee.edit-details')}
      className="flex flex-col px-6 overflow-y-hidden"
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && !hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantEmployeeForm employee={employee} onClose={closeModal} />
      )}
    </Modal>
  );
};
