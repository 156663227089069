import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  COMPANY_USER_ROLE,
  useAuthUserCompany,
  useGetSingleCompanyLocationQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditCompanyLocationForm } from '@/components/company/locations/edit-company-location-form';

export const EditCompanyLocationModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locationId } = useParams();

  if (!locationId) {
    throw new Error('Location ID are required for editing location');
  }

  const company = useAuthUserCompany();
  const hasEditPermission =
    company.role === COMPANY_USER_ROLE.MANAGER ||
    company.role === COMPANY_USER_ROLE.OWNER;

  const {
    data: location,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleCompanyLocationQuery({
    company_id: company.id,
    location_id: Number(locationId),
  });

  const closeModal = () => navigate(`/company/locations`);

  const isModalOpen = Boolean(
    useMatch({
      path: '/company/locations/edit/:locationId',
    }),
  );

  return (
    <Modal
      key={locationId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.edit')}
      description={t('company-locations.edit-details')}
      className="overflow-hidden flex flex-col"
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditCompanyLocationForm location={location} closeModal={closeModal} />
      )}
    </Modal>
  );
};
