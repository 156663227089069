import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserCompany,
  useGetCompanyLocationsQuery,
  useGetCompanyNumberOfOrdersPerEmployeeReportQuery,
} from '@durma-soft/gros-sdk';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectSingleCompanyLocations } from '@/components/shared/select-single-company-locations';
import { CompanyOrdersByEmployeeModal } from '@/components/company/reports/orders-by-company-employee-modal';
import { NumberOfCustomerCompanyOrders } from '@/components/company/reports/number-of-customer-company-orders';

import print from '@/lib/print/print';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';
import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { repeat } from '@/utils/helpers';

// TODO: MOVE TO SDK
interface ShortCompanyLocation {
  id: number;
  name: string;
}

const CompanyCustomerOrders = () => {
  const { t } = useTranslation();
  const { employeeId } = useParams();

  const [locations, setLocations] = useState<ShortCompanyLocation[]>([]);

  const onUpdateLocations = (newLocations: ShortCompanyLocation[]) => {
    setLocations(newLocations);
  };

  const company = useAuthUserCompany();

  const { from, to, setDateRange } = useDatePickerRangeState();

  const {
    data: companyLocations,
    isSuccess: isSuccess,
    isLoading: isLoading,
  } = useGetCompanyLocationsQuery({
    company_id: company.id,
  });

  const {
    data = [],
    isSuccess: isOrdersPerEmployeeSuccess,
    isLoading: isOrdersPerEmpoyeeLoading,
  } = useGetCompanyNumberOfOrdersPerEmployeeReportQuery({
    company_id: company.id,
    start_date: from,
    end_date: to,
  });

  const matchedLocations = useMemo(() => {
    if (!isOrdersPerEmployeeSuccess) return [];
    if (!locations.length) return data;
    const selectedLocationIds = locations.map(({ id }) => id);
    return data?.filter(({ id }) => selectedLocationIds.includes(id));
  }, [data, isOrdersPerEmployeeSuccess, locations]);

  return (
    <>
      <Helmet>
        <title>{t('reports.restaurant-customers.title')}</title>
      </Helmet>
      {Boolean(employeeId) && <CompanyOrdersByEmployeeModal key={employeeId} />}
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.restaurant-customers.title')}
        </h1>
      </div>
      <div className="flex gap-5 mt-5 mb-5">
        <div>
          <DatePickerWithRange
            date={{ from: new Date(from), to: new Date(to) }}
            setDate={([from, to]) => setDateRange({ from, to })}
          />
        </div>
        <div>
          {isSuccess && (
            <SelectSingleCompanyLocations
              locations={companyLocations}
              isLoading={isLoading}
              selectedLocations={locations}
              onUpdateLocations={onUpdateLocations}
            />
          )}
        </div>
        <div className="flex-1 justify-end flex">
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <div className="flex flex-col gap-10">
                    {matchedLocations.map(({ id, name, users }) => (
                      <div
                        key={id}
                        className="flex flex-col gap-4 print-in-separate-page"
                      >
                        <h3 className="text-xl font-medium">{name}</h3>
                        <NumberOfCustomerCompanyOrders forPrint users={users} />
                      </div>
                    ))}
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col">
        {isOrdersPerEmpoyeeLoading && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}

        {!isOrdersPerEmpoyeeLoading &&
          data.length === 0 &&
          locations.length === 0 && (
            <p className="text-center">{t('common.choose-location')}</p>
          )}

        {!isOrdersPerEmpoyeeLoading &&
          data.length === 0 &&
          locations.length !== 0 && (
            <p className="text-center">{t('common.no-results')}</p>
          )}

        {isOrdersPerEmployeeSuccess && !isOrdersPerEmpoyeeLoading && (
          <TableSizeSyncProvider>
            <div className="flex flex-col gap-10">
              {matchedLocations.map(({ id, name, users }, index) => (
                <div
                  key={id}
                  className="flex flex-col gap-4 print-in-separate-page"
                >
                  <h3 className="text-xl font-medium">{name}</h3>
                  <NumberOfCustomerCompanyOrders
                    isTableSyncReference={index === 0}
                    users={users}
                  />
                </div>
              ))}
            </div>
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default CompanyCustomerOrders;
