import { toast } from 'sonner';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  ValidationError,
  useAuthUserRestaurant,
  useGetRestaurantMenusQuery,
  useChangeMenuActiveStatusMutation,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { Alert } from '@/components/shared/alert';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/shared/shadcn-ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/shared/shadcn-ui/table';
import { Switch } from '@/components/shared/shadcn-ui/switch';

import { repeat } from '@/utils/helpers';

interface MenusListProps {
  date: string;
}

export const MenusList = ({ date }: MenusListProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const { data, isLoading, isSuccess, isError } = useGetRestaurantMenusQuery({
    restaurant_id: restaurant.id,
    date,
  });

  const changeActiveMenuStatus = useChangeMenuActiveStatusMutation();

  const isEmpty = isSuccess && Object.keys(data).length === 0;

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="w-24 h-6" />
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-3">
          {repeat(12).map((_, index) => (
            <Skeleton key={index} className="w-full h-72" />
          ))}
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <Alert
        title={t('common.error')}
        description={t('menu.error-loading')}
        variant="destructive"
        className="mx-auto"
      />
    );
  }

  if (isEmpty) {
    return (
      <div className="text-center text-muted-foreground">
        <p>{t('menu.no-data')}</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-16 lg:grid-cols-3">
      {Object.entries(data!).map(([key, value]) => {
        return (
          <div key={key}>
            <h2 className="mb-3 text-lg font-semibold md:text-xl">
              {key}. {t('menu.shift').toLowerCase()}
            </h2>
            <div className="flex flex-col gap-8">
              {value.map((menu) => {
                return (
                  <Card
                    role="button"
                    key={menu.id}
                    className="flex flex-col w-full rounded-md"
                  >
                    <CardHeader className="flex flex-row items-center justify-between p-3 space-y-0 bg-accent rounded-tl-md rounded-tr-md">
                      <Link
                        to={
                          hasEditPermission
                            ? `edit/${menu.id}?date=${date}`
                            : location.pathname
                        }
                        className="flex-1"
                      >
                        <CardTitle
                          title={menu.menu_type.name}
                          className="text-lg truncate text-accent-foreground"
                        >
                          {menu.menu_type.name}
                        </CardTitle>
                      </Link>
                      <Switch
                        disabled={changeActiveMenuStatus.isPending}
                        onCheckedChange={async (value) => {
                          try {
                            await changeActiveMenuStatus.mutateAsync({
                              restaurant_id: restaurant.id,
                              menu_id: menu.id,
                              is_active: value,
                            });
                            toast.success(t('menu.change-status-success'));
                          } catch (error) {
                            if (error instanceof ValidationError) {
                              return toast.error(error.message);
                            }
                            toast.error(t('menu.change-status-error'));
                          }
                        }}
                        checked={menu.is_active}
                      />
                    </CardHeader>
                    <Link
                      to={
                        hasEditPermission
                          ? `edit/${menu.id}?date=${date}`
                          : location.pathname
                      }
                    >
                      <CardContent className="flex-1 p-0 rounded-bl-md rounded-br-md bg-card">
                        <Table>
                          <TableBody>
                            {menu.foods.map((food) => (
                              <TableRow key={food.id}>
                                <TableCell>{food.name}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Link>
                  </Card>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
