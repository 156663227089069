import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useAuthUserCompany,
  useGetCompanyLocationsQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { CreateCompanyEmployeeForm } from '@/components/company/employees/create-company-employee-form';

export const CreateCompanyEmployeeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { locationId } = useParams();

  const company = useAuthUserCompany();

  const { data: locations, isSuccess } = useGetCompanyLocationsQuery({
    company_id: company.id,
  });

  const closeModal = () => {
    if (locationId) {
      return navigate(`/company/locations/${locationId}/employees`);
    }
    navigate(`/company/employees`);
  };

  const paths = [
    '/company/employees/create',
    '/company/locations/:locationId/employees/create',
  ];

  const isModalOpen = paths.find((path) => matchPath(path, pathname));

  return (
    <Modal
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('employee.create')}
      description={t('employee.create-details')}
      className="overflow-hidden flex flex-col"
    >
      {isSuccess && (
        <CreateCompanyEmployeeForm
          locations={locations}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
