import { ColumnDef } from '@tanstack/react-table';
import { NumberOfOrdersPerEmployeeReportUser } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';
import { Link } from '@/components/shared/link';
import { ReceiptText } from 'lucide-react';

export const columns = (
  t: TType,
): ColumnDef<NumberOfOrdersPerEmployeeReportUser>[] => [
  {
    accessorKey: 'first_name',
    header: t('common.first-name'),
  },
  {
    accessorKey: 'last_name',
    header: t('common.last-name'),
  },
  {
    header: t('reports.restaurant-customers.ordered'),
    cell: (cellData) => <>{cellData.row.original.ordered}</>,
  },
  {
    header: t('reports.restaurant-customers.picked-up'),
    cell: (cellData) => (
      <>
        {cellData.row.original.picked_up
          ? cellData.row.original.picked_up
          : '/'}
      </>
    ),
  },
  {
    accessorKey: 'details',
    header: '',
    cell: (cellData) => {
      return (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                to={`/restaurant/reports/restaurant-customer-orders/single-employee/${cellData.row.original.id}`}
                className="flex items-center justify-center text-gray-700 "
              >
                <ReceiptText className="w-5 h-5" />
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              {t('reports.orders-by-employee.details')}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
];
