import { ColumnDef } from '@tanstack/react-table';
import { OrdersByFoodReportRow } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<OrdersByFoodReportRow>[] => [
  {
    header: t('common.order-number'),
    cell: ({ row: { index } }) => <>{Number(index + 1)}</>,
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'total_quantity',
    header: t('common.total'),
  },
  {
    header: t('common.users'),
    cell: (cellData) => {
      const { users } = cellData.row.original;
      return (
        <>
          {users.map((user, i) => {
            return (
              <span>
                {`${user.first_name} ${user.last_name}`}{' '}
                <span className="font-medium">({user.quantity}x)</span>
                {i !== users.length - 1 && ', '}
              </span>
            );
          })}
        </>
      );
    },
  },
];
