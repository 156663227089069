import { useMemo } from 'react';
import { format } from 'date-fns';
import { CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { Locale } from '@durma-soft/gros-sdk';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/shared/shadcn-ui/button';
import { Calendar } from '@/components/shared/shadcn-ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';

import { cn } from '@/utils/helpers';
import { locales } from '@/config/global/languages';

type StringTuple = [string, string];

interface DatePickerWithRangeProps {
  className?: string;
  date?: DateRange;
  setDate: (newDate: StringTuple) => unknown;
}

export function DatePickerWithRange({
  className,
  date,
  setDate,
}: DatePickerWithRangeProps) {
  const { t, i18n } = useTranslation();

  const triggerText = useMemo(() => {
    if (!date?.from) return <span>{t('common.choose-date')}</span>;
    const formattedFrom = format(date.from, 'PPP', {
      locale: locales[i18n.language as Locale],
    });
    if (!date.to) return formattedFrom;
    const formattedTo = format(date.to, 'PPP', {
      locale: locales[i18n.language as Locale],
    });
    if (formattedFrom === formattedTo) return formattedFrom;
    return `${formattedFrom} - ${formattedTo}`;
  }, [date?.from, date?.to, t, i18n.language]);

  const goToPreviousDate = () => {
    if (date?.from && date?.to) {
      const previousDate = new Date(date.from);
      previousDate.setDate(previousDate.getDate() - 1);
      const previousToDate = new Date(date.to);
      previousToDate.setDate(previousToDate.getDate() - 1);
      setDate([
        format(previousDate, 'yyyy-MM-dd'),
        format(previousToDate, 'yyyy-MM-dd'),
      ]);
    }
  };

  const goToNextDate = () => {
    if (date?.from && date?.to) {
      const nextDate = new Date(date.from);
      nextDate.setDate(nextDate.getDate() + 1);
      const nextToDate = new Date(date.to);
      nextToDate.setDate(nextToDate.getDate() + 1);
      setDate([
        format(nextDate, 'yyyy-MM-dd'),
        format(nextToDate, 'yyyy-MM-dd'),
      ]);
    }
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'justify-start text-left font-normal px-2 gap-1',
              !date && 'text-muted-foreground',
            )}
          >
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                goToPreviousDate();
              }}
              className=""
            >
              <ChevronLeft className="w-5 h-5" />
            </div>
            <CalendarIcon className="w-4 h-4" />
            <span>{triggerText}</span>
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                goToNextDate();
              }}
            >
              <ChevronRight className="w-5 h-5" />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(newDate) => {
              if (!newDate) {
                if (!date?.from) return;
                const output = format(date.from, 'yyyy-MM-dd');
                return setDate([output, output]);
              }
              if (!newDate.from) return;
              const { from, to } = newDate;
              const formattedFrom = format(from, 'yyyy-MM-dd');
              const formattedTo = to ? format(to, 'yyyy-MM-dd') : formattedFrom;
              setDate([formattedFrom, formattedTo]);
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
