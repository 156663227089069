import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  useAuthUserRestaurant,
  useGetRestaurantCompanyLocationsQuery,
  useGetSingleRestaurantCompanyQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantCompanyEmployeeForm } from '@/components/restaurant/companies/create-restaurant-company-employee-form';

export const CreateRestaurantCompanyEmployeeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { companyId, locationId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }

  const restaurant = useAuthUserRestaurant();

  const { data: company, isSuccess: isSuccessCompany } =
    useGetSingleRestaurantCompanyQuery({
      restaurant_id: restaurant.id,
      company_id: Number(companyId),
    });

  const { data: locations, isSuccess: isSuccessLocations } =
    useGetRestaurantCompanyLocationsQuery({
      restaurant_id: restaurant.id,
      company_id: Number(companyId),
    });

  const isSuccess = isSuccessCompany && isSuccessLocations;

  const closeModal = () => {
    if (locationId) {
      return navigate(
        `/restaurant/companies/${companyId}/locations/${locationId}/employees`,
      );
    }
    navigate(`/restaurant/companies/${companyId}/employees`);
  };

  const paths = [
    '/restaurant/companies/:companyId/employees/create',
    '/restaurant/companies/:companyId/locations/:locationId/employees/create',
  ];

  const isModalOpen = paths.find((path) => matchPath(path, pathname));

  return (
    <Modal
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('employee.create')}
      description={t('employee.create-details')}
      className="overflow-hidden flex flex-col"
    >
      {isSuccess && (
        <CreateRestaurantCompanyEmployeeForm
          company={company}
          locations={locations}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
