import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetOrdersByEmployeeReportQuery,
} from '@durma-soft/gros-sdk';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-location';
import { OrdersByEmployee } from '@/components/restaurant/reports/orders-by-employee';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import print from '@/lib/print/print';
import { repeat } from '@/utils/helpers';

const OrdersReport = () => {
  const { t } = useTranslation();

  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);

  const onUpdateCompanyLocations = (
    newCompanyLocations: ShortRestaurantLocation[],
  ) => {
    setCompanyLocations(newCompanyLocations);
  };

  const { from, to, setDateRange } = useDatePickerRangeState();

  const restaurant = useAuthUserRestaurant();

  const {
    data: companies,
    isSuccess: isSuccessCompanies,
    isLoading: isLoadingCompanies,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const {
    data = [],
    isLoading: isLoadingOrders,
    isSuccess: isSuccessOrders,
  } = useGetOrdersByEmployeeReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
  });

  const matchedLocations = useMemo(() => {
    if (!isSuccessOrders) return [];
    if (!companyLocations.length) return data;
    const selectedCompanyLocationIds = companyLocations.map(({ id }) => id);
    return data.filter(({ id }) => selectedCompanyLocationIds.includes(id));
  }, [data, isSuccessOrders, companyLocations]);

  return (
    <>
      <Helmet>
        <title>{t('reports.orders.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.orders.title')}
        </h1>
      </div>
      <div className="flex gap-5 mt-5 mb-5">
        <div>
          <DatePickerWithRange
            date={{
              from: new Date(from),
              to: new Date(to),
            }}
            setDate={([from, to]) => {
              setDateRange({ from, to });
            }}
          />
        </div>
        <div>
          {isSuccessCompanies && (
            <SelectCompanyLocations
              companies={companies}
              isLoading={isLoadingCompanies}
              selectedCompanyLocations={companyLocations}
              onUpdateCompanyLocations={onUpdateCompanyLocations}
            />
          )}
        </div>
        <div className="flex-1 justify-end flex">
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <TableSizeSyncProvider>
                    <div className="flex flex-col gap-10">
                      {matchedLocations.map(({ name, id, orders }) => (
                        <div
                          key={id}
                          className="flex flex-col gap-4 print-in-separate-page"
                        >
                          <h3 className="text-xl font-medium">{name}</h3>
                          <OrdersByEmployee orders={orders} forPrint />
                        </div>
                      ))}
                    </div>
                  </TableSizeSyncProvider>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col">
        {isLoadingOrders && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}
        {!isLoadingOrders &&
          data.length === 0 &&
          companyLocations.length === 0 && (
            <p className="text-center">{t('common.choose-location')}</p>
          )}

        {!isLoadingOrders &&
          data.length === 0 &&
          companyLocations.length !== 0 && (
            <p className="text-center">{t('common.no-results')}</p>
          )}

        {isSuccessOrders && !isLoadingOrders && (
          <TableSizeSyncProvider>
            <div className="flex flex-col gap-10">
              {matchedLocations.map(({ name, id, orders }, index) => (
                <div
                  key={id}
                  className="flex flex-col gap-4 print-in-separate-page"
                >
                  <h3 className="text-xl font-medium">{name}</h3>
                  <OrdersByEmployee
                    isTableSyncReference={index === 0}
                    orders={orders}
                  />
                </div>
              ))}
            </div>
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default OrdersReport;
