import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Locale } from '@durma-soft/gros-sdk';
import { CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';

import { Button } from '@/components/shared/shadcn-ui/button';
import { Calendar } from '@/components/shared/shadcn-ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';

import { cn } from '@/utils/helpers';
import { locales } from '@/config/global/languages';

interface DatePickerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => unknown;
  date?: Date;
  setDate: (state: Date | undefined) => unknown;
}

export function DatePicker({
  date,
  setDate,
  open,
  onOpenChange,
}: DatePickerProps) {
  const { t, i18n } = useTranslation();

  const goToPreviousDate = () => {
    if (date) {
      const previousDate = new Date(date);
      previousDate.setDate(previousDate.getDate() - 1);
      setDate(previousDate);
    }
  };

  const goToNextDate = () => {
    if (date) {
      const nextDate = new Date(date);
      nextDate.setDate(nextDate.getDate() + 1);
      setDate(nextDate);
    }
  };

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-max justify-start text-left font-normal px-2 gap-1',
            !date && 'text-muted-foreground',
          )}
        >
          <div
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              goToPreviousDate();
            }}
          >
            <ChevronLeft className="w-5 h-5" />
          </div>
          <CalendarIcon className="w-4 h-4" />
          {date ? (
            format(date, 'PPP', { locale: locales[i18n.language as Locale] })
          ) : (
            <span>{t('common.choose-date')}</span>
          )}
          <div
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              goToNextDate();
            }}
          >
            <ChevronRight className="w-5 h-5" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          ISOWeek
          defaultMonth={date}
        />
      </PopoverContent>
    </Popover>
  );
}
