import { SetStateAction, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import {
  asYouTypePhoneNumber,
  phoneNumberInputPlaceholder,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { InputAddon } from '@/components/shared/input-addon';
import { CustomFileInput } from '@/components/shared/custom-file-input';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { RestaurantPartnerFormData } from '@/types/partners';

interface PartnerDetailsProps {
  formData: RestaurantPartnerFormData;
  countryCode: CountryCode;
  setFormData: (state: SetStateAction<RestaurantPartnerFormData>) => void;
  setCountryCode: (state: SetStateAction<CountryCode>) => void;
}

export const PartnerDetails = ({
  formData,
  countryCode,
  setFormData,
  setCountryCode,
}: PartnerDetailsProps) => {
  const uid = useId();
  const { t } = useTranslation();

  return (
    <div className="grid gap-6 p-2">
      <div className="grid gap-4">
        {/* Name */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            required
            id={uid + '-name'}
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder={t('common.name-placeholder')}
          />
        </div>
        {/* Username suffix */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-username-suffix'}>
            {t('partner.suffix')}
          </Label>
          <div className="flex items-stretch">
            <InputAddon position="left">@</InputAddon>
            <Input
              required
              className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
              id={uid + '-username-suffix'}
              value={formData.username_suffix}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  username_suffix: e.target.value,
                })
              }
              placeholder={t('partner.suffix-placeholder')}
            />
          </div>
        </div>
        {/* Owner phone number */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-phone-number'}>
            {t('common.phone-number')}
          </Label>
          <div className="flex items-stretch">
            <InputAddon position="left">
              <SelectCountryCallingCode
                value={countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value as CountryCode);
                  setFormData({
                    ...formData,
                    phone_number: '',
                  });
                }}
              />
            </InputAddon>
            <Input
              className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
              id={uid + '-phone-number'}
              placeholder={phoneNumberInputPlaceholder[countryCode]}
              value={formData.phone_number || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  phone_number: asYouTypePhoneNumber(
                    e.target.value,
                    countryCode,
                  ),
                })
              }
            />
          </div>
        </div>
        {/* Logo */}
        <div className="flex flex-col gap-2">
          <CustomFileInput
            label={t('common.add-logo')}
            handleFile={(logo: File) => setFormData({ ...formData, logo })}
            hasDeleteButton={Boolean(formData.logo)}
            onDelete={() => setFormData({ ...formData, logo: null })}
          />
          <div>
            {formData.logo && (
              <img
                src={URL.createObjectURL(formData.logo)}
                height="128"
                width="128"
                className="object-contain object-center w-32 h-32 rounded-md"
              />
            )}
          </div>
        </div>
      </div>
      <div className="grid gap-4">
        {/* Street */}
        <h4 className="text-lg">{t('common.address')}</h4>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-street'}>
            {t('common.address-details.street')}
          </Label>
          <Input
            id={uid + '-street'}
            value={formData.street || ''}
            onChange={(e) =>
              setFormData({ ...formData, street: e.target.value })
            }
            placeholder={t('common.address-details.street-placeholder')}
          />
        </div>
        {/* City */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-city'}>
            {t('common.address-details.city')}
          </Label>
          <Input
            id={uid + '-city'}
            value={formData.city || ''}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            placeholder={t('common.address-details.city-placeholder')}
          />
        </div>
        {/* ZIP */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-zip'}>
            {t('common.address-details.zip')}
          </Label>
          <Input
            id={uid + '-zip'}
            value={formData.zip || ''}
            onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
            placeholder={t('common.address-details.zip-placeholder')}
          />
        </div>
        {/* State */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-state'}>
            {t('common.address-details.state')}
          </Label>
          <Input
            id={uid + '-state'}
            value={formData.state || ''}
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
            placeholder={t('common.address-details.state-placeholder')}
          />
        </div>
        {/* Country */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-country'}>
            {t('common.address-details.country')}
          </Label>
          <Input
            id={uid + '-country'}
            value={formData.country || ''}
            onChange={(e) =>
              setFormData({ ...formData, country: e.target.value })
            }
            placeholder={t('common.address-details.country-placeholder')}
          />
        </div>
      </div>
    </div>
  );
};
