import { format } from 'date-fns';
import { ColumnDef } from '@tanstack/react-table';
import { OrdersBySingleEmployeeReportOrder } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

export const columns = (
  t: TType,
): ColumnDef<OrdersBySingleEmployeeReportOrder>[] => [
  {
    header: t('common.order-number'),
    cell: ({ row: { index } }) => <>{Number(index + 1)}</>,
  },
  {
    accessorKey: 'date',
    header: t('common.date'),
    cell: (cellData) => {
      return format(cellData.row.original.date, 'd.M.yyyy');
    },
  },
  {
    accessorKey: 'food_name',
    header: t('menu.foods'),
  },
];
