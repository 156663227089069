import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@/components/shared/modal';
import { CreateRestaurantCompanyLocationForm } from '@/components/restaurant/companies/create-restaurant-company-location-form';

export const CreateRestaurantCompanyLocationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }
  const closeModal = () => {
    navigate(`/restaurant/companies/${companyId}/locations`);
  };

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/companies/:companyId/locations/create' }),
  );

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.create')}
      description={t('company-locations.create-details')}
    >
      <CreateRestaurantCompanyLocationForm closeModal={closeModal} />
    </Modal>
  );
};
